export const flagResolve = (flagCode) => {
    const codes = [
        {
            code: 'af',
            iso: true,
            name: 'Afghanistan',
        },
        {
            code: 'ax',
            iso: true,
            name: 'Aland Islands',
        },
        {
            code: 'al',
            iso: true,
            name: 'Albania',
        },
        {
            code: 'dz',
            iso: true,
            name: 'Algeria',
        },
        {
            code: 'as',
            iso: true,
            name: 'American Samoa',
        },
        {
            code: 'ad',
            iso: true,
            name: 'Andorra',
        },
        {
            code: 'ao',
            iso: true,
            name: 'Angola',
        },
        {
            code: 'ai',
            iso: true,
            name: 'Anguilla',
        },
        {
            code: 'aq',
            iso: true,
            name: 'Antarctica',
        },
        {
            code: 'ag',
            iso: true,
            name: 'Antigua and Barbuda',
        },
        {
            code: 'ar',
            iso: true,
            name: 'Argentina',
        },
        {
            code: 'am',
            iso: true,
            name: 'Armenia',
        },
        {
            code: 'aw',
            iso: true,
            name: 'Aruba',
        },
        {
            code: 'ac',
            iso: false,
            name: 'Ascension Island',
        },
        {
            code: 'au',
            iso: true,
            name: 'Australia',
        },
        {
            code: 'at',
            iso: true,
            name: 'Austria',
        },
        {
            code: 'az',
            iso: true,
            name: 'Azerbaijan',
        },
        {
            code: 'bs',
            iso: true,
            name: 'Bahamas',
        },
        {
            code: 'bh',
            iso: true,
            name: 'Bahrain',
        },
        {
            code: 'bd',
            iso: true,
            name: 'Bangladesh',
        },
        {
            code: 'bb',
            iso: true,
            name: 'Barbados',
        },
        {
            code: 'by',
            iso: true,
            name: 'Belarus',
        },
        {
            code: 'be',
            iso: true,
            name: 'Belgium',
        },
        {
            code: 'bz',
            iso: true,
            name: 'Belize',
        },
        {
            code: 'bj',
            iso: true,
            name: 'Benin',
        },
        {
            code: 'bm',
            iso: true,
            name: 'Bermuda',
        },
        {
            code: 'bt',
            iso: true,
            name: 'Bhutan',
        },
        {
            code: 'bo',
            iso: true,
            name: 'Bolivia',
        },
        {
            code: 'bq',
            iso: true,
            name: 'Bonaire, Sint Eustatius and Saba',
        },
        {
            code: 'ba',
            iso: true,
            name: 'Bosnia and Herzegovina',
        },
        {
            code: 'bw',
            iso: true,
            name: 'Botswana',
        },
        {
            code: 'bv',
            iso: true,
            name: 'Bouvet Island',
        },
        {
            code: 'br',
            iso: true,
            name: 'Brazil',
        },
        {
            code: 'io',
            iso: true,
            name: 'British Indian Ocean Territory',
        },
        {
            code: 'bn',
            iso: true,
            name: 'Brunei Darussalam',
        },
        {
            code: 'bg',
            iso: true,
            name: 'Bulgaria',
        },
        {
            code: 'bf',
            iso: true,
            name: 'Burkina Faso',
        },
        {
            code: 'bi',
            iso: true,
            name: 'Burundi',
        },
        {
            code: 'cv',
            iso: true,
            name: 'Cabo Verde',
        },
        {
            code: 'kh',
            iso: true,
            name: 'Cambodia',
        },
        {
            code: 'cm',
            iso: true,
            name: 'Cameroon',
        },
        {
            code: 'ca',
            iso: true,
            name: 'Canada',
        },
        {
            code: 'ic',
            iso: false,
            name: 'Canary Islands',
        },
        {
            code: 'es-ct',
            iso: false,
            name: 'Catalonia',
        },
        {
            code: 'es-pv',
            iso: false,
            name: 'Basque Country',
        },
        {
            code: 'ky',
            iso: true,
            name: 'Cayman Islands',
        },
        {
            code: 'cf',
            iso: true,
            name: 'Central African Republic',
        },
        {
            code: 'cefta',
            iso: false,
            name: 'Central European Free Trade Agreement',
        },
        {
            code: 'ea',
            iso: false,
            name: 'Ceuta & Melilla',
        },
        {
            code: 'td',
            iso: true,
            name: 'Chad',
        },
        {
            code: 'cl',
            iso: true,
            name: 'Chile',
        },
        {
            code: 'cn',
            iso: true,
            name: 'China',
        },
        {
            code: 'cx',
            iso: true,
            name: 'Christmas Island',
        },
        {
            code: 'cp',
            iso: false,
            name: 'Clipperton Island',
        },
        {
            code: 'cc',
            iso: true,
            name: 'Cocos (Keeling) Islands',
        },
        {
            code: 'co',
            iso: true,
            name: 'Colombia',
        },
        {
            code: 'km',
            iso: true,
            name: 'Comoros',
        },
        {
            code: 'ck',
            iso: true,
            name: 'Cook Islands',
        },
        {
            code: 'cr',
            iso: true,
            name: 'Costa Rica',
        },
        {
            code: 'hr',
            iso: true,
            name: 'Croatia',
        },
        {
            code: 'cu',
            iso: true,
            name: 'Cuba',
        },
        {
            code: 'cw',
            iso: true,
            name: 'Cura\u00e7ao',
        },
        {
            code: 'cy',
            iso: true,
            name: 'Cyprus',
        },
        {
            code: 'cz',
            iso: true,
            name: 'Czech Republic',
        },
        {
            code: 'ci',
            iso: true,
            name: "C\u00f4te d'Ivoire",
        },
        {
            code: 'cd',
            iso: true,
            name: 'Democratic Republic of the Congo',
        },
        {
            code: 'dk',
            iso: true,
            name: 'Denmark',
        },
        {
            code: 'dg',
            iso: false,
            name: 'Diego Garcia',
        },
        {
            code: 'dj',
            iso: true,
            name: 'Djibouti',
        },
        {
            code: 'dm',
            iso: true,
            name: 'Dominica',
        },
        {
            code: 'do',
            iso: true,
            name: 'Dominican Republic',
        },
        {
            code: 'ec',
            iso: true,
            name: 'Ecuador',
        },
        {
            code: 'eg',
            iso: true,
            name: 'Egypt',
        },
        {
            code: 'sv',
            iso: true,
            name: 'El Salvador',
        },
        {
            code: 'gb-eng',
            iso: false,
            name: 'England',
        },
        {
            code: 'gq',
            iso: true,
            name: 'Equatorial Guinea',
        },
        {
            code: 'er',
            iso: true,
            name: 'Eritrea',
        },
        {
            code: 'ee',
            iso: true,
            name: 'Estonia',
        },
        {
            code: 'sz',
            iso: true,
            name: 'Eswatini',
        },
        {
            code: 'et',
            iso: true,
            name: 'Ethiopia',
        },
        {
            code: 'eu',
            iso: false,
            name: 'Europe',
        },
        {
            code: 'fk',
            iso: true,
            name: 'Falkland Islands',
        },
        {
            code: 'fo',
            iso: true,
            name: 'Faroe Islands',
        },
        {
            code: 'fm',
            iso: true,
            name: 'Federated States of Micronesia',
        },
        {
            code: 'fj',
            iso: true,
            name: 'Fiji',
        },
        {
            code: 'fi',
            iso: true,
            name: 'Finland',
        },
        {
            code: 'fr',
            iso: true,
            name: 'France',
        },
        {
            code: 'gf',
            iso: true,
            name: 'French Guiana',
        },
        {
            code: 'pf',
            iso: true,
            name: 'French Polynesia',
        },
        {
            code: 'tf',
            iso: true,
            name: 'French Southern Territories',
        },
        {
            code: 'ga',
            iso: true,
            name: 'Gabon',
        },
        {
            code: 'es-ga',
            iso: false,
            name: 'Galicia',
        },
        {
            code: 'gm',
            iso: true,
            name: 'Gambia',
        },
        {
            code: 'ge',
            iso: true,
            name: 'Georgia',
        },
        {
            code: 'de',
            iso: true,
            name: 'Germany',
        },
        {
            code: 'gh',
            iso: true,
            name: 'Ghana',
        },
        {
            code: 'gi',
            iso: true,
            name: 'Gibraltar',
        },
        {
            code: 'gr',
            iso: true,
            name: 'Greece',
        },
        {
            code: 'gl',
            iso: true,
            name: 'Greenland',
        },
        {
            code: 'gd',
            iso: true,
            name: 'Grenada',
        },
        {
            code: 'gp',
            iso: true,
            name: 'Guadeloupe',
        },
        {
            code: 'gu',
            iso: true,
            name: 'Guam',
        },
        {
            code: 'gt',
            iso: true,
            name: 'Guatemala',
        },
        {
            code: 'gg',
            iso: true,
            name: 'Guernsey',
        },
        {
            code: 'gn',
            iso: true,
            name: 'Guinea',
        },
        {
            code: 'gw',
            iso: true,
            name: 'Guinea-Bissau',
        },
        {
            code: 'gy',
            iso: true,
            name: 'Guyana',
        },
        {
            code: 'ht',
            iso: true,
            name: 'Haiti',
        },
        {
            code: 'hm',
            iso: true,
            name: 'Heard Island and McDonald Islands',
        },
        {
            code: 'va',
            iso: true,
            name: 'Holy See',
        },
        {
            code: 'hn',
            iso: true,
            name: 'Honduras',
        },
        {
            code: 'hk',
            iso: true,
            name: 'Hong Kong',
        },
        {
            code: 'hu',
            iso: true,
            name: 'Hungary',
        },
        {
            code: 'is',
            iso: true,
            name: 'Iceland',
        },
        {
            code: 'in',
            iso: true,
            name: 'India',
        },
        {
            code: 'id',
            iso: true,
            name: 'Indonesia',
        },
        {
            code: 'ir',
            iso: true,
            name: 'Iran',
        },
        {
            code: 'iq',
            iso: true,
            name: 'Iraq',
        },
        {
            code: 'ie',
            iso: true,
            name: 'Ireland',
        },
        {
            code: 'im',
            iso: true,
            name: 'Isle of Man',
        },
        {
            code: 'il',
            iso: true,
            name: 'Israel',
        },
        {
            code: 'it',
            iso: true,
            name: 'Italy',
        },
        {
            code: 'jm',
            iso: true,
            name: 'Jamaica',
        },
        {
            code: 'jp',
            iso: true,
            name: 'Japan',
        },
        {
            code: 'je',
            iso: true,
            name: 'Jersey',
        },
        {
            code: 'jo',
            iso: true,
            name: 'Jordan',
        },
        {
            code: 'kz',
            iso: true,
            name: 'Kazakhstan',
        },
        {
            code: 'ke',
            iso: true,
            name: 'Kenya',
        },
        {
            code: 'ki',
            iso: true,
            name: 'Kiribati',
        },
        {
            code: 'xk',
            iso: false,
            name: 'Kosovo',
        },
        {
            code: 'kw',
            iso: true,
            name: 'Kuwait',
        },
        {
            code: 'kg',
            iso: true,
            name: 'Kyrgyzstan',
        },
        {
            code: 'la',
            iso: true,
            name: 'Laos',
        },
        {
            code: 'lv',
            iso: true,
            name: 'Latvia',
        },
        {
            code: 'lb',
            iso: true,
            name: 'Lebanon',
        },
        {
            code: 'ls',
            iso: true,
            name: 'Lesotho',
        },
        {
            code: 'lr',
            iso: true,
            name: 'Liberia',
        },
        {
            code: 'ly',
            iso: true,
            name: 'Libya',
        },
        {
            code: 'li',
            iso: true,
            name: 'Liechtenstein',
        },
        {
            code: 'lt',
            iso: true,
            name: 'Lithuania',
        },
        {
            code: 'lu',
            iso: true,
            name: 'Luxembourg',
        },
        {
            code: 'mo',
            iso: true,
            name: 'Macau',
        },
        {
            code: 'mg',
            iso: true,
            name: 'Madagascar',
        },
        {
            code: 'mw',
            iso: true,
            name: 'Malawi',
        },
        {
            code: 'my',
            iso: true,
            name: 'Malaysia',
        },
        {
            code: 'mv',
            iso: true,
            name: 'Maldives',
        },
        {
            code: 'ml',
            iso: true,
            name: 'Mali',
        },
        {
            code: 'mt',
            iso: true,
            name: 'Malta',
        },
        {
            code: 'mh',
            iso: true,
            name: 'Marshall Islands',
        },
        {
            code: 'mq',
            iso: true,
            name: 'Martinique',
        },
        {
            code: 'mr',
            iso: true,
            name: 'Mauritania',
        },
        {
            code: 'mu',
            iso: true,
            name: 'Mauritius',
        },
        {
            code: 'yt',
            iso: true,
            name: 'Mayotte',
        },
        {
            code: 'mx',
            iso: true,
            name: 'Mexico',
        },
        {
            code: 'md',
            iso: true,
            name: 'Moldova',
        },
        {
            code: 'mc',
            iso: true,
            name: 'Monaco',
        },
        {
            code: 'mn',
            iso: true,
            name: 'Mongolia',
        },
        {
            code: 'me',
            iso: true,
            name: 'Montenegro',
        },
        {
            code: 'ms',
            iso: true,
            name: 'Montserrat',
        },
        {
            code: 'ma',
            iso: true,
            name: 'Morocco',
        },
        {
            code: 'mz',
            iso: true,
            name: 'Mozambique',
        },
        {
            code: 'mm',
            iso: true,
            name: 'Myanmar',
        },
        {
            code: 'na',
            iso: true,
            name: 'Namibia',
        },
        {
            code: 'nr',
            iso: true,
            name: 'Nauru',
        },
        {
            code: 'np',
            iso: true,
            name: 'Nepal',
        },
        {
            code: 'nl',
            iso: true,
            name: 'Netherlands',
        },
        {
            code: 'nc',
            iso: true,
            name: 'New Caledonia',
        },
        {
            code: 'nz',
            iso: true,
            name: 'New Zealand',
        },
        {
            code: 'ni',
            iso: true,
            name: 'Nicaragua',
        },
        {
            code: 'ne',
            iso: true,
            name: 'Niger',
        },
        {
            code: 'ng',
            iso: true,
            name: 'Nigeria',
        },
        {
            code: 'nu',
            iso: true,
            name: 'Niue',
        },
        {
            code: 'nf',
            iso: true,
            name: 'Norfolk Island',
        },
        {
            code: 'kp',
            iso: true,
            name: 'North Korea',
        },
        {
            code: 'mk',
            iso: true,
            name: 'North Macedonia',
        },
        {
            code: 'gb-nir',
            iso: false,
            name: 'Northern Ireland',
        },
        {
            code: 'mp',
            iso: true,
            name: 'Northern Mariana Islands',
        },
        {
            code: 'no',
            iso: true,
            name: 'Norway',
        },
        {
            code: 'om',
            iso: true,
            name: 'Oman',
        },
        {
            code: 'pk',
            iso: true,
            name: 'Pakistan',
        },
        {
            code: 'pw',
            iso: true,
            name: 'Palau',
        },
        {
            code: 'pa',
            iso: true,
            name: 'Panama',
        },
        {
            code: 'pg',
            iso: true,
            name: 'Papua New Guinea',
        },
        {
            code: 'py',
            iso: true,
            name: 'Paraguay',
        },
        {
            code: 'pe',
            iso: true,
            name: 'Peru',
        },
        {
            code: 'ph',
            iso: true,
            name: 'Philippines',
        },
        {
            code: 'pn',
            iso: true,
            name: 'Pitcairn',
        },
        {
            code: 'pl',
            iso: true,
            name: 'Poland',
        },
        {
            code: 'pt',
            iso: true,
            name: 'Portugal',
        },
        {
            code: 'pr',
            iso: true,
            name: 'Puerto Rico',
        },
        {
            code: 'qa',
            iso: true,
            name: 'Qatar',
        },
        {
            code: 'cg',
            iso: true,
            name: 'Republic of the Congo',
        },
        {
            code: 'ro',
            iso: true,
            name: 'Romania',
        },
        {
            code: 'ru',
            iso: true,
            name: 'Russia',
        },
        {
            code: 'rw',
            iso: true,
            name: 'Rwanda',
        },
        {
            code: 're',
            iso: true,
            name: 'R\u00e9union',
        },
        {
            code: 'bl',
            iso: true,
            name: 'Saint Barth\u00e9lemy',
        },
        {
            code: 'sh',
            iso: true,
            name: 'Saint Helena, Ascension and Tristan da Cunha',
        },
        {
            code: 'kn',
            iso: true,
            name: 'Saint Kitts and Nevis',
        },
        {
            code: 'lc',
            iso: true,
            name: 'Saint Lucia',
        },
        {
            code: 'mf',
            iso: true,
            name: 'Saint Martin',
        },
        {
            code: 'pm',
            iso: true,
            name: 'Saint Pierre and Miquelon',
        },
        {
            code: 'vc',
            iso: true,
            name: 'Saint Vincent and the Grenadines',
        },
        {
            code: 'ws',
            iso: true,
            name: 'Samoa',
        },
        {
            code: 'sm',
            iso: true,
            name: 'San Marino',
        },
        {
            code: 'st',
            iso: true,
            name: 'Sao Tome and Principe',
        },
        {
            code: 'sa',
            iso: true,
            name: 'Saudi Arabia',
        },
        {
            code: 'gb-sct',
            iso: false,
            name: 'Scotland',
        },
        {
            code: 'sn',
            iso: true,
            name: 'Senegal',
        },
        {
            code: 'rs',
            iso: true,
            name: 'Serbia',
        },
        {
            code: 'sc',
            iso: true,
            name: 'Seychelles',
        },
        {
            code: 'sl',
            iso: true,
            name: 'Sierra Leone',
        },
        {
            code: 'sg',
            iso: true,
            name: 'Singapore',
        },
        {
            code: 'sx',
            iso: true,
            name: 'Sint Maarten',
        },
        {
            code: 'sk',
            iso: true,
            name: 'Slovakia',
        },
        {
            code: 'si',
            iso: true,
            name: 'Slovenia',
        },
        {
            code: 'sb',
            iso: true,
            name: 'Solomon Islands',
        },
        {
            code: 'so',
            iso: true,
            name: 'Somalia',
        },
        {
            code: 'za',
            iso: true,
            name: 'South Africa',
        },
        {
            code: 'gs',
            iso: true,
            name: 'South Georgia and the South Sandwich Islands',
        },
        {
            code: 'kr',
            iso: true,
            name: 'South Korea',
        },
        {
            code: 'ss',
            iso: true,
            name: 'South Sudan',
        },
        {
            code: 'es',
            iso: true,
            name: 'Spain',
        },
        {
            code: 'lk',
            iso: true,
            name: 'Sri Lanka',
        },
        {
            code: 'ps',
            iso: true,
            name: 'State of Palestine',
        },
        {
            code: 'sd',
            iso: true,
            name: 'Sudan',
        },
        {
            code: 'sr',
            iso: true,
            name: 'Suriname',
        },
        {
            code: 'sj',
            iso: true,
            name: 'Svalbard and Jan Mayen',
        },
        {
            code: 'se',
            iso: true,
            name: 'Sweden',
        },
        {
            code: 'ch',
            iso: true,
            name: 'Switzerland',
        },
        {
            code: 'sy',
            iso: true,
            name: 'Syria',
        },
        {
            code: 'tw',
            iso: true,
            name: 'Taiwan',
        },
        {
            code: 'tj',
            iso: true,
            name: 'Tajikistan',
        },
        {
            code: 'tz',
            iso: true,
            name: 'Tanzania',
        },
        {
            code: 'th',
            iso: true,
            name: 'Thailand',
        },
        {
            code: 'tl',
            iso: true,
            name: 'Timor-Leste',
        },
        {
            code: 'tg',
            iso: true,
            name: 'Togo',
        },
        {
            code: 'tk',
            iso: true,
            name: 'Tokelau',
        },
        {
            code: 'to',
            iso: true,
            name: 'Tonga',
        },
        {
            code: 'tt',
            iso: true,
            name: 'Trinidad and Tobago',
        },
        {
            code: 'ta',
            iso: false,
            name: 'Tristan da Cunha',
        },
        {
            code: 'tn',
            iso: true,
            name: 'Tunisia',
        },
        {
            code: 'tr',
            iso: true,
            name: 'Turkey',
        },
        {
            code: 'tm',
            iso: true,
            name: 'Turkmenistan',
        },
        {
            code: 'tc',
            iso: true,
            name: 'Turks and Caicos Islands',
        },
        {
            code: 'tv',
            iso: true,
            name: 'Tuvalu',
        },
        {
            code: 'ug',
            iso: true,
            name: 'Uganda',
        },
        {
            code: 'ua',
            iso: true,
            name: 'Ukraine',
        },
        {
            code: 'ae',
            iso: true,
            name: 'United Arab Emirates',
        },
        {
            code: 'gb',
            iso: true,
            name: 'United Kingdom',
        },
        {
            code: 'un',
            iso: false,
            name: 'United Nations',
        },
        {
            code: 'um',
            iso: true,
            name: 'United States Minor Outlying Islands',
        },
        {
            code: 'us',
            iso: true,
            name: 'United States of America',
        },
        {
            code: 'xx',
            iso: false,
            name: 'Unknown',
        },
        {
            code: 'uy',
            iso: true,
            name: 'Uruguay',
        },
        {
            code: 'uz',
            iso: true,
            name: 'Uzbekistan',
        },
        {
            code: 'vu',
            iso: true,
            name: 'Vanuatu',
        },
        {
            code: 've',
            iso: true,
            name: 'Venezuela',
        },
        {
            code: 'vn',
            iso: true,
            name: 'Vietnam',
        },
        {
            code: 'vg',
            iso: true,
            name: 'Virgin Islands (British)',
        },
        {
            code: 'vi',
            iso: true,
            name: 'Virgin Islands (U.S.)',
        },
        {
            code: 'gb-wls',
            iso: false,
            name: 'Wales',
        },
        {
            code: 'wf',
            iso: true,
            name: 'Wallis and Futuna',
        },
        {
            code: 'eh',
            iso: true,
            name: 'Western Sahara',
        },
        {
            code: 'ye',
            iso: true,
            name: 'Yemen',
        },
        {
            code: 'zm',
            iso: true,
            name: 'Zambia',
        },
        {
            code: 'zw',
            iso: true,
            name: 'Zimbabwe',
        },
    ];
    return codes.find((code) => code.code == flagCode).name;
};

export default flagResolve;
